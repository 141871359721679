import React, {useState} from 'react';
import {KTIcon} from '../../../_websoft/helpers';
import {ToastContainer} from 'react-toastify';
import TitleComponent from '../../components/TitleComponent/TitleComponent';
import notifyToast from "../../components/Toast/Toast";
import {nalogIzvjestaj} from "../../api/izvjestaj";
import NalogIzvjestajPdf from "./components/Izvjestaji/NalogIzvjestajPdf";
import {usePDF} from "@react-pdf/renderer";
import PlaceholderDocument from "./components/PdfPageTemplate/PlaceholderDocument";
import DnevniIzvjestajPdf from "./components/Izvjestaji/DnevniIzvjestajPdf";
import PeriodicniIzvjestajPdf from "./components/Izvjestaji/PeriodicniIzvjestajPdf";
import {Link} from "react-router-dom";
import DetaljanIzvjestajNalogaPanela from "./components/Izvjestaji/DetaljanIzvjestajNalogaPanela";
import {useNavigate} from 'react-router-dom';


const Izvjestaji = () => {
    const [brojNaloga, setBrojNaloga] = useState('');
    const [stavka, setStavka] = useState('');
    /*  const [dnevniDatum, setDnevniDatum] = useState('');
      const [periodicniOd, setPeriodicniOd] = useState('');
      const [periodicniDo, setPeriodicniDo] = useState('');*/
    const [loading, setLoading] = useState(false);
    const [nalog, setNalog] = useState<any[]>([]);
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const params = {
                brojNaloga: brojNaloga,
                stavka: stavka
            };
            const response = await nalogIzvjestaj(params);
            const data = response.data;
            const nalogData = Array.isArray(data) ? data : [data];

            if (nalogData.length > 0 && typeof nalogData[0] === 'object') {
                setNalog(nalogData);
                console.log(nalogData, 'Received data');

                // Close modal by setting state
                setModalOpen(false);

                // Navigate to the new page
                navigate('../detaljan-izvjestaj-naloga-panela', {state: {nalog: nalogData[0]}});
            } else {
                console.error('Data structure is not as expected', data);
                notifyToast(false, 'Nema obračunata lezarina na odabrani datum');
            }
        } catch (error) {
            console.error('Error when calculating lezarina:', error);
            notifyToast(false, 'Failed to fetch lezarina data');
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div className='card-header border-0 cursor-pointer' role='button'>
                <div className='card-title m-0'>
                    <TitleComponent name='Izvještaji'/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    <div className='row'>
                        <div className='col-lg-4 col-sm-12 p-3 mb-6'>
                            <Link to='glavni-izvjestaj'>
                                <button type='button' className='btn btn-primary'>
                                    <KTIcon iconName='plus' className='fs-2'/>
                                    Glavni Izvještaj
                                </button>
                            </Link>
                        </div>
                        <div className='col-lg-4 col-sm-12 p-3 mb-6'>
                            <Link to='glavni-izvjestaj-v2'>
                                <button type='button' className='btn btn-primary'>
                                    <KTIcon iconName='plus' className='fs-2'/>
                                    Glavni Izvještaj V2
                                </button>
                            </Link><br/><br/>
                            <span>
                                 Stara verzija do 07/2024
                            </span>
                        </div>
                    </div>
                    <div className='row'>
                        {/* Buttons for different modals */}
                        <div className='col-lg-4 col-sm-12 p-3 mb-6'>
                            <Link to='detaljan-izvjestaj-naloga-panela'>
                                <button type='button' className='btn btn-success'>
                                    <KTIcon iconName='plus' className='fs-2'/> Nalog - Detaljan Izvještaj
                                </button>
                            </Link>
                        </div>
                        <div className='col-lg-4 col-sm-12 p-3 mb-6'>
                            <Link to='dnevni-izvjestaj-panela'>
                                <button type='button' className='btn btn-info'>
                                    <KTIcon iconName='plus' className='fs-2'/> KK - Dnevni izvještaj
                                </button>
                            </Link>
                        </div>
                        <div className='col-lg-4 col-sm-12 p-3 mb-6'>
                            <Link to='periodicni-izvjestaj-panela'>
                                <button type='button' className='btn btn-warning'>
                                    <KTIcon iconName='plus' className='fs-2'/> KK - Periodični izvještaj
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Izvjestaji;
